<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <img src="../../assets/image/news/newsBg.png" alt="">
    </div>
    <div class="newContent">
      <h3>{{detailOBJ.title}}</h3>
      <div class="date">发布日期：{{ $moment(detailOBJ.createTime).format('YYYY-MM-DD') }}</div>
      <div class="content" v-html="detailOBJ.content"></div>
      <div class="bottom">
        <el-divider></el-divider>
         <div>
          上一篇： 
          <span v-if="detailOBJ.next.length>0" @click="toDetail(detailOBJ.next[0].id)">{{detailOBJ.next[0].title}}</span>
          <i v-else>已经是第一篇</i>
         </div>
         <div>
          下一篇： 
          <span v-if="detailOBJ.last.length>0" @click="toDetail(detailOBJ.last[0].id)">{{detailOBJ.last[0].title}}</span>
          <i v-else>没有下一篇了</i>
         </div>
      </div>
    </div>
  </div>
</template>
<script>
import { journalismQueryById } from "@/api/api";
export default {
  name: "detailNews",
  data(){
    return {
      detailId: "",
      detailOBJ: {
        last: [],
        next: [],
      },
    };
  },
  watch:{
    "$route.query.id":{
      handler(val,old){
        this.getData(val);
      },
      immediate: true
    }
  },
  mounted() {
    this.detailId = this.$route.query.id || "";
    if(this.detailId) this.getData(this.detailId);
  },
  methods: {
    getData(id){
      journalismQueryById({ id:id }).then((res) => {
        if (res.success == true) {
          this.detailOBJ = res.data;
        }
      });
    },
    toDetail(id){
      this.$router.push({
        path:"/detail",
        query: { id: id, activeIndex:"2"}
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.newContent{
  width: 1040px;
  background: #FFFFFF;
  padding: 40px 80px;
  margin: 3.125rem auto;
  border-radius: 10px;
  h3{
    text-align: center;
  }
  .date{
    text-align: center;
    margin: 30px 0;
    color: #888888;
  }
  .content{
    /deep/p{
      line-height: 26px!important;
      font-size: 13px!important;
      color: #666666!important;
      margin: 20px 0;
      letter-spacing: 2px!important;
      font-family: "Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Hiragino Sans GB W3,Microsoft YaHei UI,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif"!important;
      img{
        width: 1040px;
        margin: 0 auto;
        display: block;
        margin-top: 50px;
      }
      font{
        line-height: 26px!important;
        font-size: 13px!important;
        color: #666666!important;
        margin: 20px 0;
        letter-spacing: 2px!important;
        font-family: "Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Hiragino Sans GB W3,Microsoft YaHei UI,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif"!important;
        span{
          line-height: 26px!important;
          font-size: 13px!important;
          color: #666666!important;
          margin: 20px 0;
          letter-spacing: 2px!important;
          font-family: "Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Hiragino Sans GB W3,Microsoft YaHei UI,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif"!important;
        }
      }
    }
  }
}
.bottom{
  margin-top: 40px;
  div{
    margin-top: 20px;
    span{
      color: #115FEF;
      cursor: pointer;
    }
  }
}
</style>
